.amd-bs5 {
	form {
		.password {
			meter {
		    	width: 70%!important;
	    	}
	    	.noMatch {
	    		color: $dark-red;
	    	}
	    	.match {
	    		color: $cyan-blue;
	    	}				
		}
	}
}